<template>
  <v-card>
    <DeleteDialog
      :dialog="dialog"
      v-on:confirm-delete="deleteItem"
      v-on:cancel-dialog="dialog = false"
    />
    <v-container fluid>
      <v-row>
        <v-col sm="4" md="4" lg="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="this.$t('common.search')"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col sm="12" md="12" lg="12">
          <device-type-select
            v-model="typeSelect"
            :compact="true"
            :include-none="true"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-expansion-panels
      :multiple="multiExpand"
      hover
      accordion
      v-model="openPanels"
      @change="handlePanelChange"
    >
      <v-expansion-panel
        v-for="(app, i) in sortedCompanyApps"
        :disabled="!appHasTags(app.applicationId)"
        :key="i"
      >
        <v-expansion-panel-header
          class="py-0"
          expand-icon="mdi-arrow-down-thick"
        >
          <v-col cols="12" md="11" sm="11">
            {{ app.name }}
          </v-col>
          <v-col class="pa-0 ma-0" cols="12" md="1" sm="1">
            <v-btn @click.stop="displayMap(getTableTags(app.tags))" icon>
              <v-icon>mdi-map</v-icon>
            </v-btn>
          </v-col>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pa-0 ma-0">
          <v-data-table
            :loading="isLoading()"
            :headers="tableHeaders"
            :items="getTableTags(app.tags)"
            :items-per-page="15"
            item-key="deveui"
            :search="search"
            :custom-filter="customSearch"
            class="elevation-1"
            @click:row="(row, slot) => slot.expand(!slot.isExpanded)"
            @item-expanded="getKeysForExpanded"
            single-expand
          >
            <template v-slot:[`item.active`]="{ item }">
              <v-icon dark :color="getIconColor(hasRecentData(item))">{{
                hasRecentData(item)
              }}</v-icon>
            </template>
            <template v-slot:[`item.nodeType`]="{ item }">
              <v-chip outlined :color="getNodeTypeColor(item.nodeType)">{{
                $t("tag.nodeTypes." + item.nodeType)
              }}</v-chip>
            </template>
            <template v-slot:[`item.battery`]="{ item }">
              <span
                :key="item.deveui + 'battery' + batteryLevel(item)"
                class="so-fade-update"
                >{{ batteryLevel(item) }}</span
              >
            </template>
            <template v-slot:[`item.decoder`]="{ item }">
              {{ getFriendlyDecoder(item.decoder) }}
            </template>
            <template v-slot:[`item.rssi`]="{ item }">
              <span
                :key="item.deveui + 'rssi' + getRSSIValue(item)"
                class="so-fade-update"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :class="getSignalIconColor(getRSSIValue(item))"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ getSignalIconClass(getRSSIValue(item)) }}
                    </v-icon>
                  </template>
                  <span>{{ getRSSI(item) }}</span>
                </v-tooltip>
              </span>
            </template>
            <template v-slot:[`item.count`]="{ item }">
              <span
                :key="item.deveui + 'count' + item.count"
                class="so-fade-update"
              >
                {{ item.count }}
              </span>
            </template>
            <template v-slot:[`item.alarm`]="{ item }">
              <v-icon dark :color="alarmActive(item)">mdi-camera-timer</v-icon>
            </template>
            <template v-slot:[`item.edit`]="{ item }">
              <v-icon
                v-if="
                  permitted('Tag.Update') && owns(item.application.companyId)
                "
                dark
                color="primary"
                @click.stop="editItem(item)"
                >mdi-pencil</v-icon
              >
            </template>
            <template v-slot:[`item.delete`]="{ item }">
              <v-icon
                v-if="
                  permitted('Tag.Delete') && owns(item.application.companyId)
                "
                dark
                color="error"
                @click.stop="confirmDelete(item)"
                >mdi-delete
              </v-icon>
            </template>
            <template
              v-slot:[`item.lastContact`]="{ item }"
              class="so-fade-update"
            >
              {{ lastDataTime(item) }}
            </template>
            <template slot="no-results">
              <div>{{ $t("common.noDataFound") }}</div>
            </template>
            <template slot="no-data">
              <div>{{ $t("common.noDataFound") }}</div>
            </template>
            <template v-slot:expanded-item>
              <td :colspan="tableHeaders.length + 1" class="px-0">
                <v-container>
                  <v-row>
                    <v-col cols="12" :md="tagHasAttributes ? 8 : 12" sm="12">
                      <h1 class="text-h5 text-left">
                        {{ $t("tag.sensor.title") }}
                      </h1>
                      <v-divider />
                      <v-data-table
                        :headers="[
                          { text: $t('tagdata.fields.key'), value: 'key' },
                          { text: $t('tagdata.fields.label'), value: 'label' },
                          {
                            text: $t('tag.fields.latestValue'),
                            value: 'value',
                          },
                          { text: '', value: 'chart' },
                          { text: $t('common.timestamp'), value: 'createdAt' },
                        ]"
                        :items="currents"
                        item-key="key"
                        disable-sort
                        dense
                        disable-pagination
                        hide-default-footer
                      >
                        <template v-slot:[`item.chart`]="{ item }">
                          <v-icon
                            @click="openChart(item)"
                            color="primary"
                            class="mdi mdi-chart-box"
                          ></v-icon>
                        </template>

                        <template v-slot:[`item.publish`]="{ item }">
                          <v-checkbox
                            v-model="item.publish"
                            @click="togglePublishKey(item.tagDeveui, item.key)"
                          ></v-checkbox>
                        </template>

                        <template v-slot:[`item.edit`]="{ item }">
                          <v-icon
                            dark
                            color="primary lighten-2"
                            @click.stop="editKey(item)"
                            >mdi-pencil</v-icon
                          >
                        </template>
                        <template v-slot:[`item.setValue`]="{ item }">
                          <v-icon
                            dark
                            color="primary lighten-2"
                            @click.stop="newKeyValue(item)"
                            >mdi-plus</v-icon
                          >
                        </template>
                        <template slot="no-data">
                          <div>{{ $t("common.noDataFound") }}</div>
                        </template>
                        <template v-slot:[`item.createdAt`]="{ item }">
                          {{ humanDate(item.createdAt) }}
                        </template>
                      </v-data-table>
                    </v-col>
                    <v-divider vertical v-if="tagHasAttributes" />
                    <v-col cols="12" md="4" sm="12" v-if="tagHasAttributes">
                      <h1 class="text-h5 text-left">
                        {{ $t("tag.attributes.title") }}
                      </h1>
                      <v-divider />
                      <v-data-table
                        :headers="[
                          {
                            text: $t('tag.attributes.name'),
                            value: 'name',
                          },
                          {
                            text: $t('tag.attributes.value'),
                            value: 'value',
                          },
                        ]"
                        :items="expandedTag?.attributes"
                        item-key="name"
                        dense
                        disable-pagination
                        hide-default-footer
                      />
                    </v-col>
                  </v-row>
                </v-container>

                <v-divider></v-divider>
                <v-row style="max-width: 100%">
                  <v-spacer></v-spacer>
                  <v-btn
                    class="my-4 mr-3"
                    color="green"
                    outlined
                    rounded
                    @click="addKeyDialog = true"
                  >
                    <v-icon left color="green darken-2">mdi-leak</v-icon>
                    {{ $t("tag.newKey") }}
                  </v-btn>
                </v-row>
              </td>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-dialog v-model="chartDialog" width="45%" height="80%">
      <v-card class="d-flex flex-column" style="height: 100%">
        <v-card-text
          class="flex-grow-1 d-flex flex-column"
          style="padding-top: 70px"
        >
          <v-row class="flex-grow-1">
            <v-col cols="12">
              <standard-time-span-selector
                :value="timespan"
                @input="updateTimespan"
                short-words
              />
            </v-col>
            <v-col cols="12" class="d-flex">
              <TemplateChart
                v-if="widgetConfig?.slots?.length > 0"
                :key="JSON.stringify(widgetConfig)"
                :widget="widgetConfig"
                :tags="[expandedTag?.deveui]"
                not-template
                style="width: 100%; height: 100%; min-height: 400px"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeChart">{{
            $t("common.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addKeyDialog" max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("common.add") }}</span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            :label="$t('tagdata.fields.key')"
            v-model="newKey"
            prepend-icon="mdi-key"
            autocomplete="off"
          ></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-text-field
            :label="$t('common.value')"
            v-model="keyValue"
            prepend-icon="mdi-numeric"
            autocomplete="off"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="addKeyDialog = false">
            {{ $t("common.cancel") }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="addKey()">
            {{ $t("common.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editKeyDialog" max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("common.editLabel") }}</span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            :label="$t('tagdata.fields.label')"
            v-model="currentKey.label"
            prepend-icon="apps"
            autocomplete="off"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="red darken-1"
            text
            @click="
              editKeyDialog = false;
              deleteKeyDialog = true;
            "
          >
            {{ $t("common.delete") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editKeyDialog = false">
            {{ $t("common.cancel") }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="saveEditKey()">
            {{ $t("common.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteKeyDialog" max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("common.delete") }}</span>
        </v-card-title>
        <v-card-text>
          {{ $t("common.verify") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="deleteKeyDialog = false">
            {{ $t("common.no") }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="deleteKey()">
            {{ $t("common.yes") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="setValueDialog" max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            $t("module.functionButtonTypes.input")
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            :label="$t('common.value')"
            v-model="keyValue"
            prepend-icon="mdi-numeric"
            autocomplete="off"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="setValueDialog = false">
            {{ $t("common.cancel") }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="addKeyValue()">
            {{ $t("common.add") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showMap" max-width="700">
      <live-map :tags="mapTags" />
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import DeleteDialog from "@/components/common/DeleteDialog";
import DeviceTypeSelect from "../tag/DeviceTypeSelect.vue";
import TagRepository from "@/api/repositories/tagRepository.js";
import TemplateChart from "@/components/db/widgets/Chart.vue";
import ModuleMeta from "../../_helpers/ModuleMeta";
import StandardTimeSpanSelector from "../db/customForm/StandardTimeSpanSelector.vue";
import LiveMap from "../leaflet/LiveMap.vue";
import { tagsWithAttributeDict } from "../../_helpers/tagHelper";

export default {
  name: "TagList",

  data() {
    return {
      openPanels: [],
      backendTypeSelect: null,
      multiExpand: false,
      expandedKeys: [],
      expandedTag: null,
      search: "",
      dialog: false,
      editKeyDialog: false,
      deleteKeyDialog: false,
      addKeyDialog: false,
      setValueDialog: false,
      currentItem: {},
      currentKey: {},
      newKey: "",
      keyValue: "0",
      deleting: false,
      timespan: "24_hour",
      widgetConfig: {
        slots: [],
        meta: [
          {
            key: ModuleMeta.Enum.DEFAULT_TIMESPAN,
            value: "24_hour",
          },
        ],
      },
      chartDialog: false,
      tableHeaders: [],
      showMap: false,
      mapTags: [],
      tags: [],
    };
  },

  computed: {
    ...mapState("users", ["currentUser", "userCompany", "hasCompany"]),
    ...mapState("companies", ["status", "companyApps"]),
    ...mapState("tag", {
      tagStatus: "status",
      decoders: "decoders",
    }),
    ...mapState("tagData", { tagDataStatus: "status", currents: "currents" }),
    ...mapState("alarms", { alarms: "alarms" }),
    ...mapGetters("event", ["lastEvent"]),
    ...mapGetters("tag", ["getOpenPanels", "getTableHeaders"]),

    sortedCompanyApps() {
      let activeApps = this.companyApps.filter((a) =>
        this.appHasTags(a.applicationId)
      );

      // Sort active apps alphabetically by name
      activeApps.sort((a, b) => a.name.localeCompare(b.name));

      for (let app in activeApps) {
        activeApps[app].tags = this.filterTags(activeApps[app].applicationId);
      }

      let inactiveApps = this.companyApps.filter(
        (a) => !this.appHasTags(a.applicationId)
      );
      // Sort inactive apps alphabetically by name
      inactiveApps.sort((a, b) => a.name.localeCompare(b.name));
      return activeApps.concat(inactiveApps);
    },

    typeSelect: {
      get() {
        return this.backendTypeSelect;
      },

      set(val) {
        this.backendTypeSelect = val;

        if (val == null) {
          this.multiExpand = false;
          this.openPanels = 0;
        } else {
          let panels = [];
          for (var i = 0; i < this.sortedCompanyApps.length; i++) {
            if (
              this.sortedCompanyApps[i].tags &&
              this.sortedCompanyApps[i].tags.length > 0
            )
              panels.push(i);
          }

          this.multiExpand = true;
          this.openPanels = panels;
        }
      },
    },

    tagHasAttributes() {
      return this.expandedTag?.attributes.length > 0 ? true : false;
    },
  },

  methods: {
    ...mapActions("tag", {
      getTags: "getTagsWithInformation",
      deleteTag: "deleteTag",
      getKeys: "getKeysForTagAsync",
      updateKey: "updateTagKey",
      deleteTagKey: "deleteTagKey",
      getDecoders: "getDecoders",
      addTagKey: "addTagKey",
      setTagData: "setTagData",
    }),
    ...mapActions("tag", ["updateOpenPanels"]),
    ...mapActions("alarms", ["getAlarms"]),
    ...mapActions("tagData", {
      getDataPoints: "getSensorDatapointsLastDay",
      getCurrents: "getCurrentsForTag",
    }),
    ...mapActions("companies", ["getCompanyApplications"]),
    ...mapActions("alert", { errorAlert: "error" }),

    /**
     * Converts tags.attribute array to a dictionary of k-v pairs
     * tags.attribute: [] => tags.attribute: {}
     * Needed to map attribute values to their corresponding table headers
     * @param tags
     */
    getTableTags(tags) {
      return tagsWithAttributeDict(tags);
    },

    customSearch(value, search, item) {
      return Object.values(item)
        .concat(
          Object.values(item.attributeDict),
          Object.keys(item.attributeDict)
        )
        .some(
          (v) => v && v.toString().toLowerCase().includes(search.toLowerCase())
        );
    },

    async setupTableHeaders() {
      this.tableHeaders = this.getTableHeaders;
      this.tableHeaders = this.tableHeaders.map((x) => {
        return {
          text: x.useLangLine ? this.$t(x.langLine) : x.title,
          value: x.key,
          sortable: x.sortable ?? true,
          isAttribute: x.isAttribute,
        };
      });
      this.tableHeaders.push(
        ...[
          { text: "", value: "edit", sortable: false },
          { text: "", value: "delete", sortable: false },
        ]
      );
    },

    updateTimespan(value) {
      this.timespan = value;
      if (this.widgetConfig?.meta?.length > 0)
        this.widgetConfig.meta.at(0).value = this.timespan;
      else
        this.widgetConfig.meta.push({
          key: ModuleMeta.Enum.DEFAULT_TIMESPAN,
          value: this.timespan,
        });
    },

    async openChart(item) {
      this.widgetConfig = {
        name: `${item.tag.name} - ${item.key}`,
        meta: [
          {
            key: ModuleMeta.Enum.DEFAULT_TIMESPAN,
            value: this.timespan,
          },
        ],
        slots: [
          {
            slotId: 1,
            index: 1,
            key: item.key,
          },
        ],
      };

      this.chartDialog = true;
    },

    closeChart() {
      this.chartDialog = false;
    },

    displayMap(tags) {
      this.mapTags = tags;
      this.showMap = true;
    },

    async togglePublishKey(deveui, key) {
      let result = await TagRepository.togglePublishKey(deveui, key)
        .then(() => true)
        .catch(() => false);
      if (!result) {
        this.errorAlert(this.$t("tag.errorTogglePublishKey"));
      }
    },

    getNodeTypeColor(nodeType) {
      var color = "primary";
      switch (nodeType) {
        case 0:
          color = "primary";
          break;
        case 1:
          color = "orange";
          break;
        case 2:
          color = "green";
          break;
        case 3:
          color = "red";
          break;
        case 4:
          color = "teal";
          break;
      }

      return color;
    },

    alarmActive(item) {
      let match = false;
      this.alarms.forEach((alarm) => {
        if (
          alarm.advanced == false &&
          alarm.tag != null &&
          alarm.tag.deveui == item.deveui &&
          match == false &&
          alarm.activeAlarms.length > 0
        )
          match = true;

        if (
          alarm.advanced == true &&
          alarm.advancedAlarms.length > 0 &&
          match == false &&
          alarm.advancedAlarms.find(
            (d) => d.device.deveui.toUpperCase() == item.deveui.toUpperCase()
          ) != null
        )
          match = true;
      });

      if (match) {
        return "error";
      }

      return "grey";
    },

    filterTags(appId) {
      if (this.backendTypeSelect != null)
        return this.tags.filter(
          (t) =>
            t.application.applicationId == appId &&
            t.nodeType == this.backendTypeSelect
        );

      return this.tags.filter((t) => t.application.applicationId == appId);
    },

    batteryLevel(item) {
      if (item.battery) {
        var value = parseFloat(item.battery).toFixed(2);
        var suffix = " V";
        if (value > 1000) suffix = " mV";

        return value + suffix;
      } else return "N/A";
    },

    getRSSI(item) {
      if (item.rssi) return parseInt(item.rssi).toFixed(0) + " dBm";
      else return "N/A";
    },

    getRSSIValue(item) {
      return item.rssi ? parseInt(item.rssi) : null;
    },

    getSignalIconClass(rssi) {
      if (rssi === null) {
        return "mdi-signal-off"; // No signal
      } else if (rssi > -115) {
        return "mdi-signal-cellular-3"; // Good signal
      } else if (rssi <= -120) {
        return "mdi-signal-cellular-1"; // Bad signal
      } else {
        return "mdi-signal-cellular-2"; // Average signal
      }
    },

    getSignalIconColor(rssi) {
      if (rssi === null) {
        return "grey--text"; // No signal
      } else if (rssi > -115) {
        return "green--text"; // Good signal
      } else if (rssi <= -120) {
        return "red--text"; // Bad signal
      } else {
        return "orange--text"; // Average signal
      }
    },

    RSSIColor(item) {
      if (item.data && item.data.length > 0)
        item.data.forEach((d) => {
          if (d.key == "rssi") {
            let rssiValue = d.value;

            if (rssiValue > -80) return "green--text";
            else if (rssiValue > -105) return "light-green--text";
            else if (rssiValue > -115) return "orange--text";
            else return "red--text";
          }
        });
    },

    hasRecentData(item) {
      if (item.lastContact) {
        if (this.timeWithinHours(item.lastContact, 24)) return "mdi-check";
        else return "mdi-sleep";
      } else return "mdi-help";
    },

    lastDataTime(item) {
      if (item.lastContact) {
        return this.humanDate(item.lastContact, "dd MMM yyyy - HH:mm");
      }
      return "N/A";
    },

    appHasTags(appId) {
      if (this.tags.find((t) => t.application.applicationId == appId))
        return true;
      return false;
    },

    getFriendlyDecoder(decoder) {
      if (decoder == "" || !decoder) return "N/A";
      let decoderName = this.decoders.find(
        (d) => d.value == decoder || d.key == decoder
      );
      return decoderName ? decoderName.name : "N/A";
    },

    getIconColor(iconName) {
      switch (iconName) {
        case "mdi-sleep":
          return "warning";
        case "mdi-help":
          return "error";
        case "mdi-battery-unknown":
          return "grey";
        case "mdi-battery-50":
          return "warning";
        case "mdi-battery-10":
          return "error";
        default:
          return "success";
      }
    },

    async addDatapoints() {
      if (
        this.tableHeaders.some((x) => {
          return x.value === "count";
        })
      ) {
        var dataPoints = await this.getDataPoints();

        for (var tag in this.tags) {
          var dps = dataPoints.find(
            (dp) => dp.tag.deveui == this.tags[tag].deveui
          );
          if (dps == undefined) continue;

          this.tags[tag].count = dps.count;
        }
      }
    },

    isLoading() {
      return (
        this.status.loading ||
        this.tagStatus.loading ||
        this.tagDataStatus.loading
      );
    },

    restrict() {
      var role = this.currentUser.role.toLowerCase();

      return role === "admin" || "user";
    },

    async deleteItem() {
      this.deleting = true;
      let success = await this.deleteTag({
        tagId: this.currentItem.deveui,
      });
      this.deleting = false;
      this.dialog = false;
      if (success) {
        await this.fetchAll();
      }
    },

    async confirmDelete(item) {
      this.multiExpand = true;
      this.currentItem = item;
      this.dialog = true;
    },

    async editItem(item) {
      if (this.permitted("Tag.Update"))
        this.$router.push(`/tagdetails/${item.deveui}`);
    },

    async editKey(item) {
      this.currentKey = Object.assign({}, item);
      this.editKeyDialog = true;
    },

    async newKeyValue(item) {
      this.currentKey = Object.assign({}, item);
      this.setValueDialog = true;
    },

    async saveEditKey() {
      let payload = {
        deveui: this.currentKey.tagDeveui,
        key: this.currentKey.key,
        label: this.currentKey.label,
      };

      await this.updateKey({ payload: payload });
      await this.getCurrents(this.currentKey.tagDeveui);

      this.editKeyDialog = false;
    },

    async deleteKey() {
      await this.deleteTagKey({
        tagId: this.currentKey.tagDeveui,
        key: this.currentKey.key,
      });

      await this.getCurrents(this.expandedTag.deveui);
      this.deleteKeyDialog = false;
    },

    async addKey() {
      await this.addTagKey({
        payload: {
          deveui: this.expandedTag.deveui,
          key: this.newKey,
          virtual: true,
          value: this.keyValue,
        },
      });

      await this.getCurrents(this.expandedTag.deveui);

      this.addKeyDialog = false;
    },

    async addKeyValue() {
      let payload = { key: this.currentKey.key, value: this.keyValue };
      await this.setTagData({
        tagId: this.expandedTag.deveui,
        payload: payload,
      });

      await this.getCurrents(this.expandedTag.deveui);

      this.setValueDialog = false;
    },

    async getKeysForExpanded(param) {
      this.expandedTag = param.item;
      await this.getCurrents(param.item.deveui);
    },

    async fetchAll() {
      await this.getDecoders();
      this.tags = await this.getTags();
      await this.getAlarms();
      await this.addDatapoints();
      await this.getCompanyApplications();
    },

    handlePanelChange() {
      this.updateOpenPanels(this.openPanels);
    },
  },

  async created() {
    await this.setupTableHeaders();
    this.openPanels = this.getOpenPanels;
    await this.fetchAll();
  },

  watch: {
    lastEvent(evnt) {
      // Early exit for null events
      if (evnt == null && evnt.tag != null) return;

      if (
        this.expandedTag != null &&
        this.expandedTag.deveui == evnt.tag.deveui
      ) {
        for (var d in evnt.data.data) {
          let found = this.currents.find((e) => e.key == d);
          if (found == undefined) continue;

          found.value = evnt.data.data[d];
        }
      }

      // Find the tag in the taglist
      let foundTag = null;
      for (var app of this.sortedCompanyApps) {
        // Skip applications with no tags
        if (app.tags)
          for (var tag of app.tags) {
            if (tag.deveui === evnt.tag.deveui) {
              foundTag = tag;
              break;
            }
          }

        if (foundTag != null) break;
      }

      // Tag not found in current company
      if (foundTag == null) return;

      // Update lastContact of the foundTag
      // If the event contains RSSI VDD or battery update that aswell
      if (evnt.date) foundTag.lastContact = evnt.date;

      var battery = Object.entries(evnt.data.data).find(
        (e) => e[0].toLowerCase() == "vdd" || e[0].toLowerCase() == "battery"
      );
      if (battery) foundTag.battery = battery[1];

      var rssi = Object.entries(evnt.data.data).find(
        (e) => e[0].toLowerCase() == "rssi"
      );
      if (rssi) foundTag.rssi = rssi[1];

      foundTag.count++;
    },
  },

  components: {
    DeleteDialog,
    DeviceTypeSelect,
    TemplateChart,
    StandardTimeSpanSelector,
    LiveMap,
  },
};
</script>
<style lang="scss">
.v-expansion-panel-content__wrap {
  padding: 0px;
}

.theme--light {
  .v-expansion-panel {
    background-color: #e9e9e9 !important;
  }
}

.theme--dark {
  .v-expansion-panel {
    background-color: #383838 !important;
  }
}
.so-fade-update {
  border-radius: 10px;
  padding: 0.5rem;
  animation: flashanimation 2s;
}

@keyframes flashanimation {
  0% {
    // background: #0077ff20;
    background: #75ccff;
  }

  50% {
    // background: #08376d2c;
    background: #75ccff77;
  }

  100% {
    background: transparent;
  }
}
</style>
